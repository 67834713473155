export const logo = ['308 134', `
  <title>vinyl logo</title>
  <desc>Created with Sketch.</desc>
  <defs></defs>
  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="主页" fill="#FEFEFE">
          <g id="vinyl-logo" transform="scale(5,5)">
              <g id="Page-1">
                  <path d="M3.26297288,11.8369643 C6.90843051,11.8369643 9.87476949,8.82839286 9.87476949,5.12896429 L13.1376508,5.12896429 C13.1376508,10.6539643 8.70782034,15.1473214 3.26297288,15.1473214 L9.15254237e-05,15.1473214 L9.15254237e-05,5.12896429 L3.26297288,5.12896429 L3.26297288,11.8369643 Z" id="Fill-1"></path>
                  <path d="M33.1248814,11.8369643 L33.1248814,5.12896429 L29.862,5.12896429 L29.862,15.1473214 L33.1248814,15.1473214 C35.6647119,15.1473214 37.9848814,14.1695357 39.736678,12.5640357 L39.736678,15.1473214 C39.736678,18.84675 36.770339,21.8553214 33.1248814,21.8553214 L33.1248814,25.1656786 C38.5697288,25.1656786 42.9995593,20.6723214 42.9995593,15.1473214 L42.9995593,5.12896429 L39.736678,5.12896429 C39.736678,8.82839286 36.770339,11.8369643 33.1248814,11.8369643" id="Fill-3"></path>
                  <polygon id="Fill-5" points="44.7925424 15.1480643 48.0554237 15.1480643 48.0554237 0.00120714286 44.7925424 0.00120714286"></polygon>
                  <path d="M14.9306339,15.1476929 L18.1935153,15.1476929 C18.1935153,11.4501214 21.1598542,8.43969286 24.8053119,8.43969286 L24.8053119,15.1476929 L28.0681932,15.1476929 L28.0681932,5.12933571 L24.8053119,5.12933571 C19.3613797,5.12933571 14.9306339,9.62455 14.9306339,15.1476929" id="Fill-7"></path>
                  <path d="M51.6337932,13.4347571 C50.6480644,13.4347571 49.8490475,14.2454 49.8490475,15.2454714 C49.8490475,16.2455429 50.6480644,17.0561857 51.6337932,17.0561857 C52.6186068,17.0561857 53.4176237,16.2455429 53.4176237,15.2454714 C53.4176237,14.2454 52.6186068,13.4347571 51.6337932,13.4347571" id="Fill-8"></path>
                  <polygon id="Fill-9" points="14.9314576 3.31063571 18.194339 3.31063571 18.194339 0.00120714286 14.9314576 0.00120714286"></polygon>
                  <polygon id="Fill-10" points="14.9314576 15.1480643 18.194339 15.1480643 18.194339 5.12970714 14.9314576 5.12970714"></polygon>
              </g>
          </g>
      </g>
  </g>
`]
